import { useElement } from './core/element.js';
const name = 's-badge';
const props = {};
const style = `
:host{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  height: 18px;
  background: var(--s-color-error, ${"#ba1a1a"});
  color: var(--s-color-on-error, ${"#ffffff"});
  border-radius: 18px;
  padding: 0 3px;
  font-size: .625rem;
  box-sizing: border-box;
  vertical-align: middle;
}
:host(:empty){
  width: 8px;
  height: 8px;
  min-width: auto;
  padding: 0;
}
`;
const template = `<slot></slot>`;
export class Badge extends useElement({ style, template, props }) {
}
Badge.define(name);
