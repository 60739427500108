import { useElement } from './core/element.js';
import Select from './core/select.js';
import './ripple.js';
const name = 's-segmented-button';
const props = {
    value: ''
};
const style = `
:host{
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  border: solid 1px var(--s-color-outline, ${"#777680"});
  border-radius: 20px;
  height: 40px;
  overflow: hidden;
}
`;
const template = `<slot></slot>`;
export class SegmentedButton extends useElement({
    style, template, props,
    setup(shadowRoot) {
        const slot = shadowRoot.querySelector('slot');
        const select = new Select({ context: this, selectClass: SegmentedButtonItem, slot });
        return {
            expose: {
                get value() {
                    return select.value;
                },
                set value(value) {
                    select.value = value;
                },
                get options() {
                    return select.selects;
                },
                get selectedIndex() {
                    return select.selectedIndex;
                },
            }
        };
    }
}) {
}
const itemName = 's-segmented-button-item';
const itemProps = {
    selected: false,
    disabled: false,
    selectable: true,
    value: ''
};
const itemStyle = `
:host{
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--s-color-on-surface, ${"#1c1b1f"});
  height: 100%;
  min-width: 48px;
  padding: 0 16px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: .875rem;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  border-left: solid 1px var(--s-color-outline, ${"#777680"});
}
:host(:first-child){
  border-left-color: transparent;
  margin-left: -1px;
}
:host([selected=true]){
  background: var(--s-color-secondary-container, ${"#e2e0f9"});
  color: var(--s-color-on-secondary-container, ${"#191a2c"});
}
:host([disabled=true]){
  pointer-events: none;
  color: color-mix(in srgb, var(--s-color-on-surface, ${"#1c1b1f"}) 38%, transparent);
}
::slotted([slot]){
  width: 18px;
  height: 18px;
  color: inherit;
  fill: currentColor;
  flex-shrink: 0;
}
::slotted([slot=start]){
  margin-right: 4px;
}
::slotted([slot=end]){
  margin-right: 4px;
}
`;
const itemTemplate = `
<slot name="start"></slot>
<slot></slot>
<slot name="end"></slot>
<s-ripple class="ripple" attached="true" part="ripple"></s-ripple>
`;
export class SegmentedButtonItem extends useElement({
    style: itemStyle,
    template: itemTemplate,
    props: itemProps,
    syncProps: ['selected', 'disabled'],
    setup() {
        this.addEventListener('click', () => {
            if (!(this.parentNode instanceof SegmentedButton) || this.selected)
                return;
            if (this.selectable)
                this.dispatchEvent(new Event(`${name}:select`, { bubbles: true }));
        });
        return {
            props: {
                selected: () => {
                    if (!(this.parentNode instanceof SegmentedButton))
                        return;
                    this.dispatchEvent(new Event(`${name}:update`, { bubbles: true }));
                }
            }
        };
    }
}) {
}
SegmentedButton.define(name);
SegmentedButtonItem.define(itemName);
