import { useElement } from './core/element.js';
import './ripple.js';
const name = 's-checkbox';
const props = {
    disabled: false,
    checked: false,
    indeterminate: false
};
const style = `
:host{
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  height: 40px;
  --checkbox-color: var(--s-color-primary, ${"#5256a9"});
}
:host([disabled=true]){
  pointer-events: none;
}
.container{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  aspect-ratio: 1;
  -webkit-aspect-ratio: 1;
  border-radius: 50%;
  color: var(--checkbox-color);
}
:host([disabled=true]) .container{
  color: var(--s-color-on-surface, ${"#1c1b1f"}) !important;
  opacity: .38 !important;
}
.icon{
  width: 60%;
  height: 60%;
  fill: currentColor;
}
.icon,
.ripple{
  color: var(--s-color-on-surface-variant, ${"#46464f"});
}
:host([checked=true]) .icon,
:host([checked=true]) .ripple{
  color: currentColor;
}
.checked,
.indeterminate{
  position: absolute;
  transform: scale(.5);
  opacity: 0;
  transition: transform .1s ease-out, opacity .1s ease-out;
}
:host([checked=true]:not([indeterminate=true])) .checked,
:host([indeterminate=true]) .indeterminate{
  opacity: 1;
  transform: scale(1);
}
`;
const template = `
<div class="container" part="container">
  <svg class="icon uncheck" viewBox="0 -960 960 960">
    <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Z"></path>
  </svg>
  <svg class="icon checked" viewBox="0 -960 960 960">
    <path d="m424-312 282-282-56-56-226 226-114-114-56 56 170 170ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"></path>
  </svg>
  <svg class="icon indeterminate" viewBox="0 -960 960 960">
    <path d="M280-440h400v-80H280v80Zm-80 320q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"></path>
  </svg>
  <s-ripple class="ripple" attached="true" part="ripple"></s-ripple>
</div>
<slot></slot>
`;
export class Checkbox extends useElement({
    style, template, props, syncProps: true,
    setup() {
        this.addEventListener('click', () => {
            if (this.indeterminate)
                this.indeterminate = false;
            this.checked = !this.checked;
            this.dispatchEvent(new Event('change'));
        });
    }
}) {
}
Checkbox.define(name);
