export * from './types/HTMLAttributes.js';
const supports = { CSSStyleSheet: true };
try {
    new CSSStyleSheet();
}
catch (error) {
    supports.CSSStyleSheet = false;
}
const parseType = (value, old) => {
    if (value === undefined)
        return old;
    if (typeof old === 'string')
        return String(value);
    if (typeof old === 'number')
        return Number(value);
    if (typeof old === 'boolean') {
        if (typeof value === 'boolean')
            return value;
        return value === 'true' ? true : false;
    }
    throw new TypeError();
};
const baseStyle = `:host{ user-select: none; -webkit-user-select: none; -webkit-tap-highlight-color: transparent }`;
const setStyle = (shadowRoot, css) => {
    if (supports.CSSStyleSheet) {
        const baseStyleEl = new CSSStyleSheet();
        baseStyleEl.replaceSync(baseStyle);
        shadowRoot.adoptedStyleSheets.push(baseStyleEl);
        if (css) {
            const styleEl = new CSSStyleSheet();
            styleEl.replaceSync(css);
            shadowRoot.adoptedStyleSheets.push(styleEl);
        }
        return;
    }
    if (css) {
        const styleEl = document.createElement('style');
        styleEl.textContent = css;
        shadowRoot.insertBefore(styleEl, shadowRoot.firstChild);
    }
    const baseStyleEl = document.createElement('style');
    baseStyleEl.textContent = baseStyle;
    shadowRoot.insertBefore(baseStyleEl, shadowRoot.firstChild);
};
export const useElement = (options) => {
    const attrs = [];
    const upperAttrs = {};
    for (const key in options.props) {
        const value = key.toLowerCase();
        attrs.push(value);
        upperAttrs[value] = key;
    }
    const map = new Map();
    class Protoptype extends HTMLElement {
        static observedAttributes = attrs;
        static define(name) {
            customElements.define(name, this);
        }
        constructor() {
            super();
            const shadowRoot = this.attachShadow({ mode: 'open' });
            shadowRoot.innerHTML = options.template ?? '';
            setStyle(shadowRoot, options.style);
            const props = { ...options.props };
            const advance = {};
            for (const key in options.props) {
                const newVal = this[key];
                if (newVal !== undefined && newVal !== props[key]) {
                    advance[key] = newVal;
                }
                Object.defineProperty(this, key, {
                    configurable: true,
                    get: () => props[key],
                    set: (v) => {
                        const value = parseType(v, options.props[key]);
                        if (value === props[key])
                            return;
                        if (options.syncProps === true || options.syncProps?.includes(key)) {
                            const lowerKey = key.toLowerCase();
                            const attrValue = this.getAttribute(lowerKey);
                            const valueStr = String(value);
                            if (value === options.props?.[key] && attrValue !== null) {
                                this.removeAttribute(lowerKey);
                                return;
                            }
                            if (value !== options.props?.[key] && attrValue !== valueStr) {
                                this.setAttribute(lowerKey, valueStr);
                                return;
                            }
                        }
                        props[key] = value;
                        setups?.props?.[key]?.(value);
                    }
                });
            }
            const setups = options.setup?.apply(this, [shadowRoot]);
            const exposeDescriptors = Object.getOwnPropertyDescriptors(setups?.expose ?? {});
            for (const key in exposeDescriptors) {
                Object.defineProperty(this, key, exposeDescriptors[key]);
            }
            for (const key in advance) {
                if (options.syncProps === true || options.syncProps?.includes(key)) {
                    this.setAttribute(key, String(parseType(advance[key], options.props[key])));
                }
                this[key] = advance[key];
            }
            map.set(this, setups);
        }
        connectedCallback() {
            map.get(this)?.mounted?.();
        }
        disconnectedCallback() {
            map.get(this)?.unmounted?.();
        }
        adoptedCallback() {
            map.get(this)?.adopted?.();
        }
        attributeChangedCallback(key, _, newValue) {
            this[upperAttrs[key]] = newValue ?? undefined;
        }
    }
    return Protoptype;
};
